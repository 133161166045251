@import '~bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Poppins', sans-serif;
}

.app-div {
  max-width: 100vw;
  overflow-x: hidden;
}

.popover-arrow {
  --bs-popover-bg: #0F172A;
}

.modal {
  --bs-modal-bg: none;
  --bs-modal-border-color: none
}

.nav-link {
  --bs-nav-link-color: none;
}

.nav-tabs {
  --bs-nav-tabs-link-active-color: none;
  --bs-nav-tabs-link-active-bg: none;
  --bs-nav-tabs-link-active-border-color: none;
  border: none;
}

.Arquivo_Form {
  background-color: rgba(0, 0, 0, 0.692);
}

.nav-tabs:active {
  border-bottom: 0px;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-slate-500 {
  border-color: #64748b !important;
}

:root {
  --bs-link-hover-color: none;
  --bs-border-color: #dee2e6;
}


.cont-login {
  min-height: 70vh;
  padding-top: 15vh;
}

.login-input:focus {
  border: 2px solid aquamarine;
}

.alertas {
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}

.tabela-arredondada tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

.tabela-arredondada tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

.border1px {
  border-width: 1px;
}

.selecta {
  background-position: right 20px center !important;
  background-size: 20px;
  padding-right: 40px;
}

.painelAdm-totais {
  margin-top: -40px;
  margin-bottom: 20px;
}

.painelAdm-btn-addcliente {
  margin-top: -80px;

}

/* .form-check-input {
  --bs-form-check-bg: none;
} */

.footer-config-modal {
  display: flex;
  justify-content: space-between;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}